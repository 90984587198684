//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      value: "",
      isTabOn: 0,
      tab_title: "",
    };
  },
  props: {
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    defShow: {
      type: String,
      default: "",
    },
    Arrays: {
      //JSON格式
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    tab_click(index, item) {
      this.isTabOn = index;
      if (item != this.tab_title) {
        this.tab_title = item;
        this.$emit("change", [item]);
      }
    },
    switchTab(index, item) {
      this.isTabOn = index;
      if (item != this.tab_title) {
        this.tab_title = item.name;
        this.$emit("change", item.name);
      }
    },
    change_select(item, index) {
      this.isTabOn = index;
      this.$emit("change", [item]);
    },
  },
  watch: {
    defShow: {
      handler(newv, oldv) {
        this.value = newv;
      },
      immediate: true,
    },
  },
};
